import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const listLoginScreenPhrases = async params => {
  try {
    const response = await api.get('/login-screen-phrases', params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
