import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { listLoginScreenPhrases } from 'services/loginScreenPhrases'

import type { IUseLoginScreenPhrasesReturn } from './useLoginScreenPhrases.types'

const useLoginScreenPhrases = (): IUseLoginScreenPhrasesReturn => {
  const { data: phraseData, isLoading } = useQuery({
    queryKey: ['loginScreenPhrases'],
    queryFn: () => listLoginScreenPhrases()
  })
  const data = useMemo(() => phraseData?.data ?? [], [phraseData])

  return {
    data,
    isLoading
  }
}
export default useLoginScreenPhrases
