import React from 'react'

import { clsx } from 'clsx'

import { LightBulbIcon } from 'components/Svg'

import type { HintBoxProps } from './HintBox.types'

import { hintBoxStyles, bulbIconStyles } from './HintBox.module.css'

const HintBox = ({ hintText, className }: HintBoxProps) => {
  // We'd have to render multiple lines separately as the
  // newline character will be treated as a string by React
  const textLines = hintText.split('\n')

  return (
    <div className={clsx(hintBoxStyles, className)}>
      <div className={bulbIconStyles}>
        <LightBulbIcon />
      </div>
      <div>
        {textLines.map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </div>
    </div>
  )
}

export default HintBox
