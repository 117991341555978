import React from 'react'

import 'antd/dist/reset.css'
import '../global.css'

import { Layout, LayoutContent } from '../BaseLayout'
import { App, ConfigProvider } from 'redesign/components'
import { appTheme } from 'theme'

const NoAuthLayout = ({ children }) => (
  <ConfigProvider theme={appTheme}>
    <App>
      <Layout>
        <LayoutContent style={{ overflow: 'initial' }}>
          <main>{children}</main>
        </LayoutContent>
      </Layout>
    </App>
  </ConfigProvider>
)

export default NoAuthLayout
