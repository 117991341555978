import React, { useState, useEffect, useCallback } from 'react'

import HintBox from './components/HintBox'

import { useLoginScreenPhrases } from 'redesign/hooks/useLoginScreenPhrases'

import type { RandomHintProps } from './RandomHint.types'

// Delay time for shuffling hint phrases on login page (in milliseconds)
const LOGIN_PAGE_PHRASES_SHUFFLE_INTERVAL = 7000

const RandomHint = ({ className }: RandomHintProps) => {
  const [hintText, setHintText] = useState('')

  const {
    data: phrases,
    isLoading
  } = useLoginScreenPhrases()

  const shuffle = useCallback(() => {
    if (phrases.length) {
      const index = Math.floor(Math.random() * phrases.length)
      setHintText(phrases.map(item => item.phrase)[index])
    }
  }, [phrases])

  useEffect(() => {
    if (!isLoading) {
      shuffle()
      const intervalID = setInterval(shuffle, LOGIN_PAGE_PHRASES_SHUFFLE_INTERVAL)
      return () => clearInterval(intervalID)
    }
  }, [shuffle, isLoading])

  if (isLoading) {
    return null
  }

  return <HintBox className={className} hintText={hintText} />
}

export default RandomHint
