/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'

import { App, ConfigProvider } from 'redesign/components'
import { appTheme } from 'theme'

import { FeatureFlagsContextController } from '../modules/feature-flags/feature-flags-context-controller'

import LayoutContainer from './LayoutContainer'

const Layout = props => (
  <ConfigProvider theme={appTheme}>
    <App>
      <FeatureFlagsContextController shouldGetAuthToken={false}>
        <LayoutContainer {...props} />
      </FeatureFlagsContextController>
    </App>
  </ConfigProvider>
)

export default Layout
