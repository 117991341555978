import React from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import LoginFormLogo from '../../assets/login-form-logo.png'
import LoginForm from '../../pages/login/LoginForm'
import { AuthProvider, AuthConsumer } from '../../providers/AuthProvider'
import LoginWithGoogle from '../Auth/LoginWithGoogle'
import { Layout, LayoutContent } from '../BaseLayout'
import NetworkErrorDisplay from '../NetworkErrorDisplay'
import { Spin } from '../Spin'

import { RandomHint } from 'redesign/components/RandomHint'

import { FeatureFlagsContextController } from '../../modules/feature-flags/feature-flags-context-controller'

import { signOut } from '../../services/strapi/api'

import { useError } from '../../hooks/useError'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'

import { isNetworkError } from '../../utils/helpers'

import HeaderMenu from './HeaderMenu'

import {
  error as errorStyles,
  logoStyles,
  containerStyles,
  loginFormWrapperStyles,
  loginFormStyles,
  heroImageStyles,
  layoutStyles,
  googleLoginButtonStyles,
  separatorWrapperStyles,
  separatorLineStyles,
  separatorTextStyles,
  randomHintStyles
} from '../../pages/login/LoginForm.module.css'

import 'antd/dist/reset.css'
import '../global.css'

const queryClient = new QueryClient()

const LayoutContainer = ({ children, menuSelectedKeys }) => {
  const { features } = useFeatureFlags()
  const { error: validationError, setError: setValidationError } = useError('')

  return (
    <Layout className={layoutStyles}>
      <AuthProvider>
        <AuthConsumer>
          {({ isLoading, data, error, update, errorStatus }) => {
            if (isNetworkError(error)) {
              return <NetworkErrorDisplay errorStatus={errorStatus} />
            }

            if (isLoading) {
              return (
                <div className="spinnerContainer">
                  <Spin tip="Loading..." size="large" />
                </div>
              )
            }

            if (!data) {
              return (
                <QueryClientProvider client={queryClient}>
                  <div className={containerStyles}>
                    <div className={loginFormWrapperStyles}>
                      <div className={loginFormStyles}>
                        <img src={LoginFormLogo} className={logoStyles} alt="X-Platform logo" />
                        {validationError && (
                          <div className={errorStyles}>{validationError}</div>
                        )}
                        <FeatureFlagsContextController shouldGetAuthToken={false}>
                          {features?.OPS_GOOGLE_LOGIN && (
                            <>
                              <LoginWithGoogle className={googleLoginButtonStyles} />
                              <div className={separatorWrapperStyles}>
                                <span className={separatorLineStyles} />
                                <span className={separatorTextStyles}>or</span>
                                <span className={separatorLineStyles} />
                              </div>
                            </>
                          )}
                          <LoginForm onSuccess={data => update(data)} />
                        </FeatureFlagsContextController>
                      </div>
                    </div>
                    <div className={heroImageStyles} />
                    <RandomHint className={randomHintStyles} />
                  </div>
                </QueryClientProvider>
              )
            }

            return (
              <>
                <HeaderMenu
                  menuSelectedKeys={menuSelectedKeys}
                  authData={data}
                  onSignOut={() => {
                    signOut()
                    update(null)
                    setValidationError(null)
                  }}
                />
                <LayoutContent style={{ overflow: 'initial' }}>
                  <main>{children}</main>
                </LayoutContent>
              </>
            )
          }}
        </AuthConsumer>
      </AuthProvider>
    </Layout>
  )
}

export default LayoutContainer
